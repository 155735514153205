import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'

const Indent1 = styled.div`
  padding: 0 0 32px 20px;
`

const Indent2 = styled.div`
  padding: 0 0 32px 32px;
`

const Agb = () => {
  return (
    <GlobalWrapper>
      <Seo title="AGB" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Allgemeine Geschäfts- und Mietbedingungen</h1>
            <h2>1. Anbieter und Vermieter</h2>
            <Indent1>
              <p>Anbieter und Vermieter ist die</p>
              <p>
                <b>Hytide GmbH</b>
                <br />
                Türkenstr. 5b
                <br />
                85748 Garching
              </p>
              <p>Vertreten durch die Geschäftsführer:</p>
              <p>
                Alexander Hofmann
                <br />
                Leo Rebay
              </p>
              <p>
                Umsatzsteuer-Identifikationsnummer: DE352339423
                <br />
                Registergericht/Handelsregister: AG München HRB 274482
              </p>
              <p>
                E-Mail: info[ät]stretchzeltbayern.de
              </p>
            </Indent1>
            <h2>2. Geltungsbereich</h2>
            <Indent1>
              <p>
                2.1 Diese Allgemeinen Geschäfts- und Mietbedingungen (AGB)
                gelten für alle vertraglichen Beziehungen im Rahmen der Nutzung
                der von uns angebotenen Leistungen.
              </p>
              <p>2.2 Mieter sind Verbraucher und Unternehmer.</p>
              <Indent2>
                <p>
                  2.2.1 Verbraucher ist gemäß § 13 BGB jede natürliche Person,
                  die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend
                  weder ihrer gewerblichen noch ihrer selbständigen beruflichen
                  Tätigkeit zugerechnet werden können.
                </p>
                <p>
                  2.2.2 Unternehmer ist gemäß § 14 BGB eine natürliche oder
                  juristische Person oder eine rechtsfähige
                  Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts
                  in Ausübung ihrer gewerblichen oder selbständigen beruflichen
                  Tätigkeit handelt.
                </p>
              </Indent2>
              <p>
                2.3 Geschäftsbedingungen des Mieters werden nicht
                Vertragsgrundlage, auch wenn ihnen nicht ausdrücklich
                widersprochen wird
              </p>
            </Indent1>
            <h2>3. Angebot und Vertragsschluss</h2>
            <Indent1>
              <p>
                3.1 Bei den Angeboten von Hytide zur Miete von Gegenständen
                handelt es sich um eine unverbindliche Aufforderung an Sie,
                Gegenstände bei uns zu mieten.
              </p>
              <p>
                3.2 Angebot und Vertragsschluss außerhalb dieser Internetseite
                <br />
                Sofern der Mieter einen Vertrag mit dem Anbieter außerhalb der
                Internetseite abschließt, kommt dieser durch Angebot und Annahme
                zustande.
              </p>
              <p>
                3.3 Angebot und Vertragsschluss auf dieser Internetseite
                <br />
                Für den Abschluss eines Vertrages wählen Sie einen
                Mietgegenstand im Preis-Berechnungs-Formular aus und füllen die
                weiteren Felder aus. Anschließend geben Sie Ihre Daten ein und
                können über den Button „Jetzt anfordern“ ein Angebot von uns
                einholen.
                <br />
                Wir senden Ihnen dann ein verbindliches Angebot zu, das Sie über
                die Abgabe einer Willenserklärung (zum Beispiel über Telefon,
                E-Mail) annehmen können, durch die der Vertrag dann zustande
                kommt.
              </p>
              <p>
                3.4 Nach Zustandekommen des Vertrages erhält der Kunde eine
                Bestätigungs-E-Mail.
              </p>
              <p>
                3.5 Der Mieter hat keinen Anspruch darauf, dass der Anbieter ein
                verbindliches Angebot zusendet.
              </p>
              <p>
                3.6 Der Mieter versichert, dass die von ihm im Rahmen des
                Vertragsschlusses gemachten Angaben wahr, vollständig und
                aktuell sind.
              </p>
              <p>
                3.7 Verträge zu gesonderten Serviceleistungen, die nicht in dem
                Angebot erfasst sind, kommen durch Angebot und Annahme zustande.
                Der Kunde kann hierzu eine unverbindliche Anfrage telefonisch
                oder in Textform an den Anbieter stellen.
              </p>
            </Indent1>
            <h2>4. Mietgegenstand</h2>
            <Indent1>
              <p>
                4.1 Der Mietgegenstand ergibt sich aus dem konkreten Angebot.
              </p>
              <p>
                4.2 Der Mietgegenstand bleibt während der Dauer des
                Mietvertrages Eigentum des Vermieters.
              </p>
              <p>
                4.3 Der Mietgegenstand wird dem Mieter in einem neuwertigen oder
                gebrauchten Zustand überlassen. Das bedeutet, dass der gemietete
                Gegenstand neu oder gebraucht sein kann. Ein Anspruch auf
                Neuware besteht nicht.
              </p>
              <p>
                4.4 Hytide ist berechtigt, leicht abweichende oder ähnliche
                Materialien und Farben zur Verfügung zu stellen.
              </p>
            </Indent1>
            <h2>5. Vertragslaufzeit, Kündigung, Stornierungsgebühren</h2>
            <Indent1>
              <p>
                5.1 Der Mietzeitraum wird zwischen den Parteien vereinbart und
                ist Gegenstand des konkreten Mietvertrages.
              </p>
              <p>
                5.2 Es sind Kurzzeitmieten (1-3 Tage) und Langzeitmieten
                möglich.
              </p>
              <p>
                5.3 Der Mietzeitraum endet mit dem vereinbarten Ende der Miete
                und kann vorher nicht ordentlich gekündigt werden.
              </p>
              <p>
                5.4 Bei Beendigung des Vertrags ist der Mieter zur Rückgabe des
                Mietgegenstands in dem Zustand wie bei Übergabe verpflichtet.
              </p>
              <p>
                5.5 Erfolgt die Rückgabe der Mietsache nach Ablauf der
                vereinbarten Mietzeit, so ist Hytide berechtigt den
                zusätzlichen Leistungszeitraum zu berechnen. Dies gilt nicht,
                soweit Hytide die verspätete Rückgabe zu vertreten hat.
              </p>
              <p>
                5.6 Storniert der Mieter den Mietvertrag entstehen die
                nachfolgend aufgelisteten Stornierungsgebühren:
              </p>
              <ul>
                <li>8 Wochen vor Mietbeginn: 20 % der vereinbarten Miete</li>
                <li>4 Wochen vor Mietbeginn: 40% der vereinbarten Miete</li>
                <li>2 Wochen vor Mietbeginn: 50 % der vereinbarten Miete</li>
                <li>7 Tage vor Mietbeginn: 75 % der vereinbarten Miete</li>
                <li>
                  Bei einer Stornierung später als 24 Stunden vor Mietbeginn
                  wird die gesamte Miete fällig.
                </li>
              </ul>
              <p>
                Hytide ist berechtigt, die Stornierungsgebühren von der vom
                Mieter ggf. im Voraus geleisteten Anzahlung abzuziehen.
              </p>
              <p>
                Im Falle eines berechtigten Widerrufs des Mietvertrages als
                Verbraucher im Rahmen des gesetzlichen Widerrufsrechts, gelten
                abweichend die Bedingungen zum Widerrufsrecht und dem ggf. zu
                leistenden Wertersatz (Ziffer 9).
              </p>
            </Indent1>
            <h2>6. Übergabe und Gebrauch des Mietgegenstands</h2>
            <Indent1>
              <p>
                6.1 Der Mieter sendet eine Woche vor Mietbeginn Details zur
                Anfahrt, zum Aufstellort und Umgebung mit allen wesentlichen
                Details zu. Dies umfasst insbesondere:
              </p>
              <p>
                6.1.1 Der Mieter ist dafür verantwortlich, dass der Anfahrtsweg
                und der Aufstellplatz mit einem Kleintransporter (Breite 2,55 m,
                Länge bis 7 Meter) erreicht werden kann.
              </p>
              <p>
                6.1.2 Falls die gemieteten Zelte nicht auf einer Rasenfläche
                aufgebaut werden sollen, wofür 1,5 Meter lange Erdnägel
                verwendet werden, so ist der Mieter verpflichtet, dies
                rechtzeitig an Hytide zu kommunizieren.
              </p>
              <p>
                6.1.3 Mieter müssen sich über das Vorhandensein von Leitungen,
                Rohren, Kabeln und/ oder anderen Arbeiten am oder im Boden
                erkundigen und Hytide darüber informieren.
              </p>
              <p>
                6.2 Der Mieter ist verpflichtet den Mietgegenstand sachgerecht
                zu nutzen und stets pfleglich zu behandeln. Der Mieter ist
                verpflichtet, Maßnahmen zu ergreifen, um eine Schadenseinwirkung
                auf den Mietgegenstand zu verhindern.
              </p>
              <p>
                6.3 Änderungen, Verschieben, Verfärben oder anderweitige
                Verarbeitung und Bearbeitung des Mietobjektes ist verboten. Der
                Mieter ist ohne schriftliche Genehmigung von Hytide nicht
                berechtigt an/in der Mietsache Gegenstände aufzuhängen.
              </p>
              <p>
                6.4 Der Mieter soll das Gerät vor dem Zugriff Dritter,
                insbesondere nicht eingewiesenen Personen bzw. vor Kindern zu
                schützen. Der Mieter ist für die Aktivitäten, die in und um die
                Zelte stattfinden, verantwortlich.
              </p>
              <p>
                6.5 Eine Untervermietung ist dem Mieter nur bei vorheriger
                Zustimmung durch Hytide gestattet.
              </p>
              <p>
                6.6 Der Mieter ist für die gesamte Mietdauer für die gemieteten
                Gegenstände inklusive des Zubehörs verantwortlich.
              </p>
              <p>
                6.7 Der Mieter ist verpflichtet, den Vermieter unverzüglich über
                einen Verlust, Beschädigung oder unberechtigte Nutzung durch
                Dritte zu unterrichten.
              </p>
              <p>
                6.8 Eine Beleuchtung der Mietsache darf nur mit elektrisch
                geerdeter und genehmigter Beleuchtung erfolgen.
              </p>
              <p>
                6.9 Es ist verboten unter/in oder in der Nähe der
                Mietgegenstände Hitzequellen jeglicher Art aufzustellen oder zu
                betreiben.
              </p>
              <p>
                6.10 Starke, ungewöhnliche Verunreinigungen hat der Mieter vor
                Rückgabe zu beseitigen. Bei starker Verunreinigung am Mietobjekt
                kann Hytide nachträglich eine zusätzliche Reinigungsgebühr
                in Höhe von 4 Euro pro Quadratmeter des Zeltes fordern.
              </p>
            </Indent1>
            <h2>7. Witterung, höhere Gewalt</h2>
            <Indent1>
              <p>
                7.1 Bei extremen Wetterbedingungen behält sich Hytide das
                Recht vor, die gemieteten Zelte nicht aufzubauen falls die
                Situation durch Hytide als unsicher eingeschätzt wird.
              </p>
              <p>
                7.2 Leistungsverzögerungen aufgrund höherer Gewalt (z. B.
                Invasion, Krieg, umfassende militärische Mobilmachung,
                Bürgerkrieg, Aufruhr, Rebellion und Revolution, militärische
                oder usurpierte Macht, Aufstand, terroristische Handlungen,
                Sabotage oder Piraterie, Devisen- und Handelsbeschränkungen,
                Embargo, Sanktionen, behördliche Handlungen, ob rechtmäßig oder
                unrechtmäßig, Befolgung von Gesetzen oder behördlichen
                Anordnungen, Enteignung, Beschlagnahme von Werken,
                Verstaatlichung, Seuche, Epidemie, Sturm, Naturkatastrophe oder
                extremes Naturereignis, Explosion, Feuer, Zerstörung von
                Anlagen, längerer Ausfall von Transport, Telekommunikation,
                Informationssystemen oder Energie, allgemeine Arbeitsunruhen wie
                Boykott, Streik und Aussperrung) und Umstände im
                Verantwortungsbereich des Mieters, z.B. nicht rechtzeitige
                Erbringung von Mitwirkungsleistungen, hat Hytide nicht zu
                vertreten. Die Parteien sind verpflichtet, dem anderen Teil
                Hindernisse der vorgenannten Art unverzüglich mitzuteilen.
              </p>
              <p>
                7.3 Wird durch die vorgenannten Ereignisse (7.1 und 7.2) die
                Leistung für Hytide unmöglich, wird Hytide von der
                Leistungsverpflichtung frei, ohne dass der Mieter vom Vertrag
                zurücktreten oder Schadensersatz verlangen kann. Treten die
                vorgenannten Hindernisse bei dem Mieter ein, so gelten die
                gleichen Rechtsfolgen auch für seine Abnahmeverpflichtung.
              </p>
              <p>
                7.4 Der Mieter ist verpflichtet, die Wetterlage im Auge und die
                Zelte zu sichern ggf. abzubauen, wenn das Wetter zur Gefahr
                wird. Das kann schon ab Windstärke (Beaufort) 6 der Fall sein.
              </p>
            </Indent1>
            <h2>8. Mietzins und Zahlungen</h2>
            <Indent1>
              <p>
                8.1 Die Höhe des Mietzinses ergibt sich aus dem Mietvertrag.
              </p>
              <p>
                8.2 Die Zahlung des Mietzinses erfolgt durch Überweisung, per
                Lastschrift oder nach Absprache zwischen den Parteien durch eine
                andere Zahlungsart. Die Zahlung für den ersten Vertragsmonat ist
                mit Vertragsschluss fällig. Nachfolgende Zahlungen müssen
                spätestens sieben Tage nach Rechnungserhalt auf dem vom
                Vermieter angegebenen Bankkonto eingegangen sein, es sei denn,
                zwischen den Parteien ist eine andere Zahlungsart oder ein
                anderer Zahlungstermin vereinbart worden.
              </p>
            </Indent1>
            <h2>9. Widerrufsrecht</h2>
            <Indent1>
              <p>
                Verbrauchern steht ein Widerrufsrecht zu, wenn der Vertrag
                außerhalb von Geschäftsräumen abgeschlossen worden ist, zum
                Beispiel Online.
              </p>
              <p>9.1 Widerrufsbelehrung für Verbraucher</p>
              <p>
                <b>Widerrufsrecht</b>
              </p>
              <p>
                Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen
                diesen Vertrag zu widerrufen.
              </p>
              <p>
                Die Widerrufsfrist beträgt 14 Tage ab dem Tag des
                Vertragsabschlusses.
              </p>
              <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
              <p>
                <b>Hytide GmbH</b>
                <br />
                Türkenst. 5b
                <br />
                85748 Garching
              </p>
              <p>
                E-Mail: info[ät]stretchzeltbayern.de
              </p>
              <p>
                mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                versandter Brief, Telefax oder E-Mail) über Ihren Entschluss,
                diesen Vertrag zu widerrufen, informieren. Sie können dafür das
                beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
                vorgeschrieben ist.
              </p>
              <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                Widerrufsfrist absenden.
              </p>
              <p>
                <b>Folgen des Widerrufs</b>
              </p>
              <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
                daraus ergeben, dass Sie eine andere Art der Lieferung als die
                von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens vierzehn Tagen ab dem Tag
                zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
                Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                verwenden wir dasselbe Zahlungsmittel, das Sie bei der
                ursprünglichen Transaktion eingesetzt haben, es sei denn, mit
                Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem
                Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
              </p>
              <p>
                Haben Sie verlangt, dass die Dienstleistungen während der
                Widerrufsfrist beginnen soll, so haben Sie uns einen
                angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
                Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts
                hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
                Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
                vorgesehenen Dienstleistungen entspricht.
              </p>
              <p>
                9.2 Hinweis zum vorzeitigen Erlöschen des Widerrufsrechts Ihr
                Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von
                Dienstleistungen vorzeitig, wenn wir die Dienstleistung
                vollständig erbracht haben und mit der Ausführung der
                Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre
                ausdrückliche Zustimmung gegeben haben und gleichzeitig Ihre
                Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei
                vollständiger Vertragserfüllung durch uns verlieren.
              </p>
              Zustimmen können Sie <Link to="/zustimmungserklaerung">hier</Link>
              <p>9.3 Muster Widerrufsformular</p>
              <p>
                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
                dieses Formular aus und senden Sie es zurück:
              </p>
              <hr />
              <p>
                Hytide GmbH
                <br />
                Türkenstr. 5b
                <br />
                85748 Garching
              </p>
              <p>
                Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*)
                abgeschlossenen Vertrag über die Miete der folgenden Gegenstände
                (*)/ die Erbringung der folgenden Dienstleistung (*)
              </p>
              <ul>
                <li>Bestellt am (*)/ erhalten am (*)</li>
                <li>Name des/ der Verbraucher(s)</li>
                <li>Anschrift des/ der Verbraucher(s)</li>
                <li>
                  Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf
                  Papier)
                </li>
                <li>Datum</li>
              </ul>
              <hr />
              <p>(*) Unzutreffendes streichen.</p>
            </Indent1>
            <h2>10. Mängel an dem Mietgegenstand</h2>
            <Indent1>
              <p>
                10.1 Der Mieter hat Mängel an dem Mietgegenstand unverzüglich
                nach Bekanntwerden in Textform Hytide anzuzeigen. Unterlässt
                der Mieter die Anzeige, so ist er dem Vermieter zum Ersatz des
                daraus entstehenden Schadens verpflichtet. Soweit der Vermieter
                infolge der Unterlassung der Anzeige nicht Abhilfe schaffen
                konnte, ist der Mieter nicht berechtigt, die Miete zu mindern,
                Schadensersatz zu verlangen oder ohne Bestimmung einer
                angemessenen Frist zur Abhilfe außerordentlich zu kündigen.
              </p>
              <p>
                10.2 Im Falle eines Mangels wird der Vermieter den Mangel
                innerhalb angemessener Zeit nach eigener Wahl durch Reparatur
                der Mietsache oder durch Lieferung einer neuen Sache beheben.
                Liegt ein nur unerheblicher Mangel vor, der den vertragsgemäßen
                Gebrauch der Mietsache nicht einschränkt, besteht kein Anspruch
                des Mieters auf Reparatur der Mietsache oder Lieferung einer
                neuen Sache. Das Recht des Mieters zur Minderung bleibt
                unberührt.
              </p>
              <p>
                10.3 Stellt sich heraus, dass ein Mangel trotz Anzeige des
                Mieters nicht vorliegt, ist der Vermieter berechtigt, die mit
                der versuchten Beseitigung in Verbindung stehenden Aufwände zu
                berechnen.
              </p>
            </Indent1>
            <h2>11. Haftung</h2>
            <Indent1>
              <p>11.1 Haftung des Vermieters</p>
              <Indent2>
                <p>
                  11.1.1 Der Vermieter haftet uneingeschränkt für Schäden,
                  sofern ihm Vorsatz oder grobe Fahrlässigkeit zur Last fällt.
                </p>
                <p>
                  11.1.2 Für einfache Fahrlässigkeit haftet der Vermieter nur
                  bei Verletzung einer Pflicht, deren Erfüllung die
                  ordnungsgemäße Durchführung des Vertrages überhaupt erst
                  ermöglicht und auf deren Einhaltung der Käufer regelmäßig
                  vertrauen darf (Kardinalspflicht). Im Übrigen ist eine Haftung
                  bei Vorliegen einfacher Fahrlässigkeit ausgeschlossen.
                </p>
                <p>
                  11.1.3 Sofern der Vermieter wie vorstehend für einfache
                  Fahrlässigkeit haftet, ist die Haftung auf den Schaden
                  begrenzt, mit dessen Entstehen nach den bei Vertragsschluss
                  bekannten Umständen typischerweise gerechnet werden musste.
                </p>
                <p>
                  11.1.4 Vorgenannte Haftungsausschlüsse und -begrenzungen
                  gelten nicht, sofern eine Garantie für die Beschaffenheit der
                  Ware übernommen oder die Mangelhaftigkeit der Ware arglistig
                  verschwiegen wurde. Der Vermieter haftet ferner unbeschränkt
                  für Schäden, die nach dem Produkthaftungsgesetz von ihm zu
                  ersetzen sind, sowie für Schäden an Leben, Körper und
                  Gesundheit.
                </p>
                <p>
                  11.1.5 Hytide haftet nicht für die durch das Einsetzen
                  oder Entfernen der gemieteten Zelten verursachten Schäden an
                  Kabeln, Leitungen, Rohren oder an Boden- bzw. Rasenfläche.
                </p>
              </Indent2>
              <p>11.2 Haftung des Mieters</p>
              <Indent2>
                <p>
                  11.2.1 Der Mieter haftet für sämtliche Schäden an der
                  Mietsache und dem Zubehör (einschließlich dem Verlust oder dem
                  Untergang der Mietsache und/oder dem Zubehör). Dies gilt
                  nicht, soweit der Schaden vom Vermieter oder seinen
                  Erfüllungsgehilfen schuldhaft verursacht wurde.
                </p>
                <p>
                  11.2.2 Der Mieter haftet für Schäden an Zelten und Zubehör
                  durch Dritte.
                </p>
                <p>
                  11.2.3 Schäden, welcher Art auch immer, die durch die
                  Verankerung der gemieteten Zelte verursacht werden, z.B. an
                  Hauswänden oder Böden, werden vollständig vom Mieter getragen.
                </p>
              </Indent2>
              <p>
                11.3 Jede Partei verpflichtet sich, die jeweils andere sowie von
                sämtlichen tatsächlichen oder angedrohten Ansprüchen Dritter
                freizustellen, welche in den Verantwortlichkeitsbereich der
                jeweiligen Partei fallen. Davon erfasst sind auch Ansprüche, die
                gegenüber Mitarbeitern, Unterbeauftragten und Erfüllungsgehilfen
                der jeweiligen Partei geltend gemacht werden. Die Freistellung
                umfasst auch die Kosten angemessener Rechtsverteidigung sowie
                alle angemessenen gerichtlichen und außergerichtlichen Kosten,
                die der freigestellten Partei oder ihren jeweiligen Mitarbeitern
                und Unterauftragnehmern aus oder im Zusammenhang mit der
                tatsächlichen oder angedrohten Inanspruchnahme entstehen.
              </p>
            </Indent1>
            <h2>12. Werbung/Referenzen</h2>
            <Indent1>
              <p>
                12.1 Hytide behält sich das Recht vor, Fotos der vermieteten
                Zelte am Aufstellort zu machen und diese für eigene Werbezwecke
                zu verwenden.
              </p>
              <p>
                12.2 Der Mieter hat diesbezügliche keine Ansprüche auf Zahlung
                von Lizenzgebühren.
              </p>
              <p>
                12.3 Hytide wird bei den Aufnahmen Urheberrechte, das Recht
                am eigenen Bild von Betroffenen sowie die datenschutzrechtlichen
                Bestimmungen beachten.
              </p>
            </Indent1>
            <h2>13. Datenschutz</h2>
            <Indent1>
              <p>
                Die Datenschutzrechtlichen Bestimmungen können Sie hier
                einsehen.
              </p>
            </Indent1>
            <h2>14. Streitschlichtung</h2>
            <Indent1>
              <p>
                Die Europäische Kommission stellt im Internet eine Plattform zur
                Online-Streitbeilegung bereit, wenn Sie Verbraucher sind. Unter
                folgendem Link gelangen Sie zur Online-Streitbeilegung:{' '}
                <a href="http://ec.europa.eu/consumers/odr/">
                  http://ec.europa.eu/consumers/odr/
                </a>
              </p>
            </Indent1>
            <h2>15. Schlussbestimmungen</h2>
            <Indent1>
              <p>15.1 Die Vertragssprache ist Deutsch.</p>
              <p>15.2 Es gilt das Recht der Bundesrepublik Deutschland.</p>
              <p>
                15.3 Ist der Kunde Kaufmann, juristische Person des öffentlichen
                Rechts oder öffentlich-rechtliches Sondervermögen, gilt der
                Gerichtsstand des Geschäftssitzes des Vermieters (Garching) als
                vereinbart.
              </p>
              <p>
                15.4 Ist eine der vorstehenden Bestimmungen aufgrund
                gesetzlicher Bestimmungen, Vorschriften oder Gesetzesänderungen
                ganz oder teilweise unwirksam, bleiben alle anderen Bestimmungen
                hiervon unberührt und gelten weiterhin in vollem Umfang.
              </p>
            </Indent1>
            <p>Zuletzt aktualisiert: 11.04.2022</p>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Agb
